import clsx from 'clsx'
import { returnLineBreaks } from '~/lib/returnLineBreaks'

export type Props = {
  type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
  className?: string
  styleAs?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
  h1Serif?: boolean
  children: React.ReactNode
}

export const Headline = ({ type, styleAs = type, className, h1Serif, children }: Props) => {
  const baseStyles = 'max-w-full'
  const styling = {
    h1: 'text-primary-black text-h1-mobile md:text-h1-desktop xl:text-h1-desktop-large font-medium break-words',
    h2: 'text-primary-black text-h2-mobile md:text-h2-desktop xl:text-h2-desktop-large font-medium break-words',
    h3: 'text-primary-black text-h3-mobile md:text-h3-desktop xl:text-h3-desktop-large font-medium break-words',
    h4: 'text-primary-black text-h4-mobile md:text-h4-desktop xl:text-h4-desktop-large font-medium break-words',
    h5: 'text-primary-black text-h4-mobile md:text-h4-desktop xl:text-h4-desktop-large font-medium break-words',
  }

  {
    switch (type) {
      case 'h1':
        return (
          <h1 className={clsx(baseStyles, styling[styleAs], 'font-dm-serif', className)}>
            {typeof children === 'string' ? returnLineBreaks(children) : children}
          </h1>
        )
      case 'h2':
        return (
          <h2
            className={clsx(
              baseStyles,
              styling[styleAs],
              h1Serif ? 'font-dm-serif' : '',
              className,
            )}
          >
            {typeof children === 'string' ? returnLineBreaks(children) : children}
          </h2>
        )
      case 'h3':
        return (
          <h3 className={clsx(baseStyles, styling[styleAs], className)}>
            {typeof children === 'string' ? returnLineBreaks(children) : children}
          </h3>
        )
      case 'h4':
        return (
          <h4 className={clsx(baseStyles, styling[styleAs], className)}>
            {typeof children === 'string' ? returnLineBreaks(children) : children}
          </h4>
        )
      case 'h5':
        return (
          <h5 className={clsx(baseStyles, styling[styleAs], className)}>
            {typeof children === 'string' ? returnLineBreaks(children) : children}
          </h5>
        )
      default:
        return null
    }
  }
}
