import { ImageProps } from 'next/image'
import { CSSProperties } from 'react'
import { hasQueryString } from '~/lib/hasQueryString'
import NextImage from 'next/image'

type Props = {
  hasNoBlur?: boolean
  alt: string
  quality?: number
  fill?: boolean
  src: ImageProps['src'] | undefined | null
}

export const Image = ({
  src,
  hasNoBlur,
  alt,
  quality = 70,
  fill,
  style,
  ...rest
}: Omit<ImageProps, 'src'> & Props) => {
  if (!src) {
    return null
  }

  const stringSrc = src.toString()

  // Only apply optimization to image assets from Magento and CTF (not SVGs)
  if (
    !stringSrc.includes('.svg') &&
    (stringSrc.includes('b2b.benuta.com') || stringSrc.includes('ctfassets.net'))
  ) {
    const width = rest.width as number
    const deviceSizes =
      width && width < 2000 ? [1, 2] : [256, 384, 640, 750, 1080, 1200, 1920, 2048, 3840]

    const finalSrc = stringSrc.includes('b2b.benuta.com')
      ? stringSrc.replace(/\/cache\/[a-f0-9]+\//, '/')
      : stringSrc.replace('downloads.ctfassets.net', 'images.ctfassets.net')

    const getOptimizedSrc = () => {
      const size = deviceSizes[deviceSizes.length - 1]
      const calcualtedWidth = width && width < 2000 ? Math.floor(size * width) : size
      const srcFilter = stringSrc.includes('b2b.benuta.com')
        ? `width=${calcualtedWidth}&quality=${quality}`
        : `w=${calcualtedWidth}&q=${quality}&fm=webp`
      return hasQueryString(finalSrc as string)
        ? `${finalSrc}&${srcFilter}`
        : `${finalSrc}?${srcFilter}`
    }

    const optimizedSrcset = deviceSizes
      .map((size) => {
        if (width && width < 2000) {
          const calculatedWidth = Math.floor(size * width)
          const srcsetFilter = stringSrc.includes('b2b.benuta.com')
            ? `width=${calculatedWidth}&quality=${quality}`
            : `w=${calculatedWidth}&q=${quality}&fm=webp`
          return `${finalSrc}${
            hasQueryString(finalSrc as string) ? '&' : '?'
          }${srcsetFilter} ${size}x`
        } else {
          const srcsetFilter = stringSrc.includes('b2b.benuta.com')
            ? `width=${size}&quality=${quality}`
            : `w=${size}&q=${quality}&fm=webp`
          return `${finalSrc}${
            hasQueryString(finalSrc as string) ? '&' : '?'
          }${srcsetFilter} ${size}w`
        }
      })
      .join(', ')

    const optimizedStyle: CSSProperties = (
      rest.width
        ? style
        : {
            position: 'absolute',
            height: '100%',
            width: '100%',
            inset: '0px',
            color: 'transparent',
            ...style,
          }
    ) as CSSProperties

    return (
      <img
        decoding="async"
        loading="lazy"
        src={getOptimizedSrc()}
        srcSet={optimizedSrcset}
        alt={alt || ''}
        style={optimizedStyle}
        placeholder={hasNoBlur ? 'empty' : 'blur'}
        {...rest}
      />
    )
  } else {
    return (
      <NextImage
        src={src}
        placeholder={hasNoBlur ? 'empty' : 'blur'}
        blurDataURL="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
        fill={rest.width && rest.height ? false : !!fill}
        alt={alt}
        style={style}
        {...rest}
        unoptimized
      />
    )
  }
}
